<template>
  <div>
    <a-modal v-model="convertModal" title="Convert" @ok="handleVariable">
      <div>
        <a-form-model layout="vertical">
          <a-form-model-item
            label="Give a simple name"
            extra="Give a simple name for this field. e.g Compliant Name"
          >
            <a-input v-model="convertOptions.title" placeholder="Title" />
          </a-form-model-item>
          <a-form-model-item
            label="Select Field type"
            extra="Select a field type"
          >
            <a-select v-model="convertOptions.fieldType">
              <a-select-option value="text">Text</a-select-option>
              <a-select-option value="select">Select</a-select-option>
              <a-select-option value="multi-select"
                >Multi-Select</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-if="convertOptions.fieldType !== 'text'"
            label="Options"
            extra="Please input the available options separated with Commas"
          >
            <a-input
              v-model="convertOptions.fieldOptions"
              placeholder="Separate each with comma"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <div class="flex items-end justify-end mb-3">
      <a-button v-if="templateId === ''" type="primary" @click="saveTemplate">
        Save Template
      </a-button>
      <a-button v-else type="primary" @click="updateTemplate">
        Update Template
      </a-button>
    </div>
    <div class="flex">
      <div class="w-8/12 mr-4">
        <div class="bg-white rounded-md shadow-md p-3">
          <div class="">
            <div class="flex space-x-5 mb-4">
              <a-input
                v-model="title"
                placeholder="Agreement Template Title"
                class="w-full"
              />
              <a-input
                v-model="cost"
                placeholder="Cost of Template"
                title="number"
                class="w-full"
              />
            </div>
            <a-textarea
              v-model="description"
              placeholder="Description of this template"
            ></a-textarea>
          </div>
          <div id="editor" class="mt-5">
            <div class="container">
              <quill-editor
                ref="myQuillEditor"
                v-model="content"
                class="quill-editor"
                :options="editorOption"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-4/12">
        <div class="bg-white rounded-md shadow-md p-3">
          <p class="font-semibold text-lg">Create Variables</p>
          <div>
            <div>
              <a-form-model layout="vertical">
                <a-form-model-item
                  label="Give a simple name"
                  extra="Give a simple name for this field. e.g Compliant Name"
                >
                  <a-input v-model="convertOptions.title" placeholder="Title" />
                </a-form-model-item>
                <a-form-model-item label="Give a Description">
                  <a-textarea
                    v-model="convertOptions.description"
                    auto-size
                    placeholder="Description"
                  ></a-textarea>
                </a-form-model-item>
                <a-form-model-item
                  label="Select Field type"
                  extra="Select a field type"
                >
                  <a-select v-model="convertOptions.fieldType">
                    <a-select-option value="text">Text</a-select-option>
                    <a-select-option value="select">Select</a-select-option>
                    <a-select-option value="multichoice"
                      >Multi-Select</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  v-if="convertOptions.fieldType !== 'text'"
                  label="Options"
                  extra="Please input the available options separated with Commas"
                >
                  <a-input
                    v-model="convertOptions.fieldOptions"
                    placeholder="Separate each with comma"
                  />
                </a-form-model-item>
                <div class="flex items-center justify-end">
                  <a-button type="primary" @click="createVariables"
                    >Create
                  </a-button>
                </div>
              </a-form-model>
            </div>
          </div>
        </div>
        <div class="bg-white rounded-md shadow-md p-3 mt-4">
          <p class="font-semibold text-lg">Available Variables</p>
          <p class="mb-3 text-xs text-red-400">
            Wrap all variable name in &lt;&lt; &gt;&gt; when using them.
          </p>
          <p class="text-sm font-semibold">
            Arrangement here will be the same Arrangement shown to user on the
            Agreement Template Form, drag variables around to re-order
          </p>

          <draggable
            v-model="variables"
            group="people"
            @start="drag = true"
            @end="drag = false"
          >
            <transition-group type="transition" name="flip-list">
              <div
                v-for="(variable, i) in variables"
                :key="variable.title"
                class="text-sm py-2 border-t border-b cursor-move"
              >
                <p class="mb-0">
                  Name: <span class="font-semibold">{{ variable.title }}</span>
                </p>
                <p class="mb-0">
                  Type of Field:
                  <span class="font-semibold">{{ variable.fieldType }}</span>
                </p>
                <p class="mb-0">
                  Description:
                  <span class="font-semibold">{{ variable.description }}</span>
                </p>
                <p v-if="variable.value" class="mb-0">
                  Available options:
                  <span class="font-semibold">
                    {{ variable.values.toString() }}</span
                  >
                </p>
                <div class="flex items-center justify-between">
                  <a-button type="" @click="editVariable(i)">Edit</a-button>
                  <a-button type="" @click="deleteVariable(i)">Delete</a-button>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { camelCase } from 'change-case'
import { quillEditor } from 'vue-quill-editor'
import Draggable from 'vuedraggable'
// import TipTap from './TipTap.vue'
// import { quillEditor } from 'vue-quill-editor'
import templateApi from '../api/template'
// function getClipboard() {
import 'quill/dist/quill.core.css'
// for snow theme
import 'quill/dist/quill.snow.css'

// function getClipboard() {
//   const pasteTarget = document.createElement('input')
//   const actElem = document.activeElement.appendChild(pasteTarget).parentNode
//   pasteTarget.focus()
//   document.execCommand('Paste', null, null)
//   const paste = pasteTarget.value
//   actElem.removeChild(pasteTarget)
//   return paste
// }

export default {
  name: 'AgreementTemplate',
  components: {
    quillEditor,
    Draggable,
  },
  data: () => ({
    myArray: [
      { id: 1, name: 'Sola' },
      { id: 2, name: 'Kola' },
    ],
    editorOption: {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ['clean'],
          ['link'],
        ],
      },
    },
    content: '',
    iframe: null,
    title: '',
    description: '',
    cost: '',
    convertModal: false,
    templateId: '',
    templateData: {},
    convertOptions: {
      title: '',
      // text, select, multichoice
      fieldType: 'text',
      fieldOptions: '',
      values: [],
    },
    variables: [],
  }),
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    },
  },
  mounted() {
    // this.iframe =
    //   this.$refs.iframeRichTextField.contentDocument ||
    //   this.$refs.iframeRichTextField.contentWindow.document
    // this.iFrameOn()
    this.templateId = this.$route.query.templateId || ''
    if (this.templateId !== '') {
      this.getTemplate()
    }
    this.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      delta.ops = delta.ops.map((op) => {
        return {
          insert: op.insert,
        }
      })
      return delta
    })
  },
  created() {},
  methods: {
    // onEditorChange({ html, text }) {
    //   this.content = html
    // },
    deleteVariable(index) {
      this.variables.splice(index, 1)
    },
    editVariable(index) {
      this.convertOptions = {
        title: this.variables[index].title,
        fieldType: this.variables[index].fieldType,
        fieldTypeOptions: this.variables[index].fieldTypeOptions,
      }
      this.variables.splice(index, 1)
    },
    async saveTemplate() {
      const req = await templateApi(this.axios).create({
        title: this.title,
        description: this.description,
        html: this.content,
        cost: this.cost,
        variables: this.variables,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to create Template',
          description: req.message,
        })
        return
      }
      this.$notification.success({
        message: 'Successful',
        description: req.message,
      })
      this.$router.push('template')
    },
    async updateTemplate() {
      const req = await templateApi(this.axios).update({
        templateId: this.templateId,
        data: {
          title: this.title,
          description: this.description,
          html: this.content,
          cost: this.cost,
          variables: this.variables,
        },
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to update Template',
          description: req.message,
        })
        return
      }
      this.$notification.success({
        message: 'Successful',
        description: req.message,
      })
      this.$router.push('template')
    },
    async getTemplate() {
      const req = await templateApi(this.axios).get({
        templateId: this.templateId,
        params: {},
      })
      this.templateData = req.data
      this.title = this.templateData.title
      this.cost = this.templateData.cost
      this.description = this.templateData.description
      this.content = this.templateData.html
      this.variables = this.templateData.variables
    },
    createVariables() {
      if (this.convertOptions.fieldType === 'text') {
        this.variables.push({
          name: this.convertOptions.title,
          title: camelCase(this.convertOptions.title),
          description: this.convertOptions.description,
          fieldType: this.convertOptions.fieldType,
        })
      } else if (
        this.convertOptions.fieldType === 'select' ||
        this.convertOptions.fieldType === 'multichoice'
      ) {
        this.variables.push({
          name: this.convertOptions.title,
          title: camelCase(this.convertOptions.title),
          fieldType: this.convertOptions.fieldType,
          description: this.convertOptions.description,
          values: this.convertOptions.fieldOptions.split(','),
        })
      }
      this.convertOptions = {
        title: '',
        // text, select, multichoice
        fieldType: 'text',
        fieldOptions: '',
        description: '',
        values: [],
      }
    },
    handleVariable() {
      this.variables.push({
        title: this.convertOptions.title,
        fieldType: this.convertOptions.fieldType,
      })
    },
    iFrameOn() {
      this.iframe.designMode = 'on'
    },
    iCommand(command, name) {
      this.iframe.execCommand(command, false, name === '' ? null : name)
    },
    iFontSize() {
      const size = prompt('Enter a size 1-7', '')
      this.iframe.execCommand('FontSize', false, size)
    },
    iLink() {
      const link = prompt('Enter the URL for this link:', 'https://')
      this.iframe.execCommand('CreateLink', false, link)
    },
    iUnLink() {
      this.iframe.execCommand('Unlink', false, null)
    },
    iConvert() {
      // this.iframe.execCommand('copy')
      // const selectedText = getClipboard()
      // console.log(selectedText)
      this.iframe.execCommand('underline', false, null)
      this.convertModal = true
    },
  },
}
</script>

<style scoped>
.actions {
  @apply p-1 px-2 rounded shadow  h-8 cursor-pointer border border-gray-200 hover:shadow-sm mx-1 text-sm;
}

#editor .container .quill-editor {
  overflow-y: auto;
  height: auto;
}

.flip-list-move {
  transition: transform 0.7s;
}
</style>
