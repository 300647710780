<template>
  <div>
    <tab-headers>
      Agreement Template
      <template #text> Create and Manage agreement template </template>
    </tab-headers>
    <div class="mt-10">
      <agreement-template />
    </div>
  </div>
</template>

<script>
import AgreementTemplate from '../../components/agreementTemplate.vue'
import TabHeaders from '../../components/typography/TabHeaders.vue'
export default {
  name: 'Template',
  components: { TabHeaders, AgreementTemplate },
  layout: 'admindashboard',
}
</script>
